// ==> nz-form-item
.inner-box {
  padding: 8px;
}

.ant-form-item {
  min-height: 4rem;
  margin-bottom: 2rem;
  position: relative;
  background-color: transparent;
}

//switch empty background
.ant-form-item.emptyBackground {
  margin-bottom: 1rem;
}

// form item with checkbox
.ant-form-item.checkbox {
  align-items: center;
}

// => Label
.ant-form-item-label {
  position: absolute;
  z-index: 2;
  padding-left: 1rem;

  .ant-form-vertical &,
  .ant-col-24&,
  .ant-col-xl-24& {
    padding: 0 0 0 1rem;
  }

  > label.ant-form-item-required:not(.ant-form-item-required-mark-optional) {
    &::before {
      content: '';
      margin-right: 0;
    }

    &::after {
      content: 'requis';
      right: 0;
      display: inline-block;
      margin-left: 1rem;
      color: black;
      font-size: 14px;
      font-weight: bold;
      opacity: 0.4;
      font-family: SimSun, sans-serif;
      line-height: 0;
    }
  }
}

// => Control
.ant-form-item-control {
  height: 100%;
}

// => Label in error
.ant-form-item-explain,
.ant-form-item-extra {
  clear: both;
  position: absolute;
  top: 4.5rem;
  left: 1rem;
  color: #000;
  font-size: 12px;
  line-height: 1.5715;
  transition: color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
}

// => Comment label error (transaction-mass and drawer)
.details-ocr-form,
.details-business-form,
.rejected-business-form,
.ineligible-drawer-form,
.location-ko-form {

  .ocr-mass-comment-wrapper {
    .ant-form-item-explain-error,
    .ant-form-item-extra {
      clear: both;
      position: relative;
      top: 12.5rem;
      font-size: 1rem;
      line-height: 1.5715;
      transition: color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
    }
  }

  .ocr-drawer-comment-wrapper,
  .rejected-business-comment-wrapper,
  .ineligible-drawer-comment-wrapper,
  .location-ko-comment-wrapper {

    .ant-form-item-explain-error,
    .ant-form-item-extra {
      clear: both;
      position: relative;
      top: 8.5rem;
      line-height: 1.5715;
      transition: color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
    }
  }
}