@import "../styles/colors";

.contract-tabs .ant-tabs-nav-wrap {
  margin-left: 150px;
}
.contract-tabs .ant-tabs {
  background-color: transparent;
  .ant-tabs-nav {
    justify-content: space-between;
  }
}
.ant-tabs {
  background-color: white;

  > .ant-tabs-nav .ant-tabs-nav-wrap {
    flex-shrink: 0;
    flex-grow: 0;
    border-bottom: 1px solid @grey-light2;
    width: auto;
    transition: width 1s;
  }

  &.tabset-fix > .ant-tabs-nav .ant-tabs-nav-wrap {
    width: calc(100% - 46px);
  }

  a.ng-star-inserted {
    color: @grey-light;
  }

  .ant-tabs-tab-active {
    a.ng-star-inserted {
      color: @text-color;

      &:before {
        border-bottom: 2px solid;
      }
    }
  }
}

.publicGroupBackground .ant-tabs,
.ant-tabs.public-group {
  background-color: @background-color-public-group;
}

.ant-tabs-tab {
  padding: 25px 40px;

  .ant-tabs-tab-btn {
    color: #A3AAB2;
    font-size: 16px;
    font-weight: 400;
  }

  &.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #4B4E6D;
    font-weight: 700;
  }
}

.ant-tabs-top,
.ant-tabs-bottom {
  > .ant-tabs-nav,
  > div > .ant-tabs-nav {
    margin-bottom: 30px;

    &::before {
      content: none;
    }

    .ant-tabs-ink-bar {
      background-color: #4B4E6D;
      height: 2px;
    }
  }
}

.ant-tabs-dropdown-menu-item {
  a {
    color: @text-color;
  }
  &.ant-dropdown-menu-item-selected {
    background-color: @grey-light2;
    a {
      font-weight: bold;
    }
  }
}

